<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>记录查询</el-breadcrumb-item>
      <el-breadcrumb-item>订单记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pk_id"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px 50px" for=""
          >车牌：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入车牌号"
            v-model="query.plateNo"
            clearable
        /></label>
        <label style="margin: 10px 50px" for=""
          >订单编号：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入订单编号"
            v-model="query.orderNo"
            clearable
        /></label>
        <label style="margin: 10px" for=""
          >创建时间：<el-date-picker
            v-model="inTimeBegin"
            @change="inTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="inTimeEnd"
            @change="outTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
          >
          </el-date-picker
        ></label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="orderInfocx"
          >查询</el-button
        >
        <el-button size="small" @click="orderInfocz">重置</el-button>
      </div>
      <el-button type="primary" size="small" @click="orderInfoexcel"
        >导出</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          ref="multipleTable"
          :data="tableData"
          @sort-change="sortchange"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderNo"
            label="订单号"
            width="150"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="plateNo"
            label="车牌号"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="id2"
            label="停车流水号"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderTime"
            label="订单时间"
            sortable="custom"
            width="150"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.orderTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderTotalMoney"
            label="订单金额"
            sortable="custom"
            width="110"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="优惠金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="实际抵扣金额"
            width="110"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.orderTotalMoney > scope.row.couponMoney
                  ? scope.row.couponMoney
                  : scope.row.orderTotalMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="actuallyPayMoney"
            label="已付金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="payStatus"
            label="支付状态"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.payStatus == 0">未支付</span>
              <span v-if="scope.row.payStatus == 1">支付成功</span>
              <span v-if="scope.row.payStatus == 2">支付失败</span>
              <span v-if="scope.row.payStatus == 3">部分支付</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="210"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="queryParkings(scope.row.id)"
                size="mini"
                >停车记录</el-button
              >
              <el-button
                type="text"
                @click="queryPayment(scope.row.id)"
                size="mini"
                >支付日志</el-button
              >
              <el-button
                type="text"
                @click="queryOrder(scope.row.id)"
                size="mini"
                >订单详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <div style="text-align: center">合计订单总金额：{{ zjine }}元</div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 停车记录 -->
    <el-dialog
      title="查看停车记录"
      :visible.sync="isShowParking"
      width="60%"
      v-el-drag-dialog
    >
      <div class="txbj">
        <ul v-for="(item, index) in queryParkingList" :key="index">
          <li>
            车牌号:
            <span>{{
              item.pkInoutData.plateNo ? item.pkInoutData.plateNo : '无'
            }}</span>
          </li>
          <li>
            停车流水号:
            <span>{{ item.inoutDataId ? item.inoutDataId : '无' }}</span>
          </li>
          <li></li>
          <li>
            进场时间:
            {{
              item.pkInoutData.inTime
                ? conversionTime(item.pkInoutData.inTime)
                : '无'
            }}
          </li>
          <li>
            离场时间:
            {{
              item.pkInoutData.outTime
                ? conversionTime(item.pkInoutData.outTime)
                : '无'
            }}
          </li>
          <li>
            停车时长:
            {{
              item.pkInoutData.inTime && item.pkInoutData.outTime
                ? timeFn(item.pkInoutData.inTime, item.pkInoutData.outTime)
                : '无'
            }}
          </li>
          <li>
            进场通道:
            {{ item.pkInoutData.inDoorName ? item.pkInoutData.inDoorName : '无' }}
          </li>
          <li>
            离场通道:
            {{
              item.pkInoutData.outDoorName ? item.pkInoutData.outDoorName : '无'
            }}
          </li>
          <li>
            应付金额: {{ item.actuallyPayMoney ? item.actuallyPayMoney : '无' }}
          </li>
          <li>
            <div>
              进场照片:
              <span
                ><img
                  :src="
                    item.pkInoutData.inPicPath ? item.pkInoutData.inPicPath : '无'
                  "
                  width="500"
              /></span>
            </div>
            <div>
              离场照片:
              <span
                ><img
                  :src="
                    item.pkInoutData.outPicPath
                      ? item.pkInoutData.outPicPath
                      : '无'
                  "
                  width="500"
              /></span>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
    <!-- 支付日志 -->
    <el-dialog
      title="查看支付日志"
      :visible.sync="isShowPayment"
      width="70%"
      v-el-drag-dialog
    >
      <el-table
        :data="queryPaymentList"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="编号" width="70"></el-table-column>
        <el-table-column
          prop="payOrderNo"
          label="订单号"
          width="140"
        ></el-table-column>
        <el-table-column
          prop="orderPayMoney"
          label="支付金额"
        ></el-table-column>
        <el-table-column prop="paidType" label="支付方式">
          <template slot-scope="scope">
            <span v-if="scope.row.paidType">{{
              findQuery(scope.row.paidType, payment)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payScene" label="支付场景">
          <template slot-scope="scope">
            <span v-if="scope.row.payScene">{{
              findQuery(scope.row.payScene, payScene)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" label="支付状态">
          <template slot-scope="scope">
            <span v-if="scope.row.payStatus">{{
              findQuery(scope.row.payStatus, payStatus)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="returnTime" label="支付时间" width="140">
          <template slot-scope="scope">
            <span>{{
              scope.row.returnTime ? conversionTime(scope.row.returnTime) : ''
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 订单详情 -->
    <el-dialog
      title="查看订单详情"
      :visible.sync="isShowOrder"
      width="60%"
      v-el-drag-dialog
    >
      <div class="txbj">
        <ul v-if="queryOrderList">
          <li>
            订单号:
            <span>{{ queryOrderList.id ? queryOrderList.id : '无' }}</span>
          </li>
          <li>
            停车流水号:
            <span>{{
              queryOrderList.orderNo ? queryOrderList.orderNo : '无'
            }}</span>
          </li>
          <li v-show="queryOrderList.payStatus === 0">支付状态: 未支付</li>
          <li v-show="queryOrderList.payStatus === 1">支付状态: 支付成功</li>
          <li v-show="queryOrderList.payStatus === 2">支付状态: 支付失败</li>
          <li v-show="queryOrderList.payStatus === 3">支付状态: 部分支付</li>
          <li>
            进场时间:
            {{
              queryOrderList.pkInoutDataDto.intime
                ? conversionTime(queryOrderList.pkInoutDataDto.intime)
                : '无'
            }}
          </li>
          <li>
            离场时间:
            {{
              queryOrderList.pkInoutDataDto.outTime
                ? conversionTime(queryOrderList.pkInoutDataDto.outTime)
                : '无'
            }}
          </li>
          <li>
            停车时长:
            {{
              queryOrderList.pkInoutDataDto.intime &&
              queryOrderList.pkInoutDataDto.outTime
                ? timeFn(
                    queryOrderList.pkInoutDataDto.intime,
                    queryOrderList.pkInoutDataDto.outTime
                  )
                : '无'
            }}
          </li>
          <li>总金额: {{ queryOrderList.orderTotalMoney? queryOrderList.orderTotalMoney :'无' }}</li>
          <li>优惠金额: {{ queryOrderList.couponMoney ? queryOrderList.couponMoney :'无' }}</li>
          <li>已付金额: {{ queryOrderList.actuallyPayMoney ? queryOrderList.actuallyPayMoney :'无' }}</li>
        </ul>
      </div>
      <el-table
        :data="queryOrderLists"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="regionName" label="停车区域"></el-table-column>
        <el-table-column prop="inDate" label="进场时间" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.inDate">{{
              conversionTime(scope.row.inDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="outDate" label="出场时间" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.outDate">{{
              conversionTime(scope.row.outDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pkDate" label="停车时长" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.outDate">{{
              timeFn(scope.row.inDate, scope.row.outDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderMoney" label="停车金额"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  orderInfo,
  selectByInoutDatatk,
  selectByPayLogtk,
  selectOrderInfotk,
  orderInfoexcel,
  findPayFailSumMoney,
} from '@/api/record.js'
import { sysDictData } from '@/api/dictionaries.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
export default {
  data() {
    return {
      isShowData: false,
      isShowParking: false,
      isShowPayment: false,
      isShowOrder: false,
      tableData: [],
      inTimeBegin: undefined, // 开始时间
      inTimeEnd: undefined, // 结束时间
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pk_id: undefined,
        plateNo: undefined, // 车牌号
        payStatus: 0, // 支付状态
        orderNo: undefined, // 订单编号
        inTimeBegin: undefined, // 开始时间
        inTimeEnd: undefined, // 结束时间
        sortField: 'order_time', // 订单时间order_time  订单金额order_total_money
        sortType: 'desc', // 排序类型 默认desc  asc升序 desc降序
      },
      total: undefined,
      payment: [], // 保存支付方式
      PkInfoSelect: [], // 保存停车场下拉框
      queryParkingList: undefined, // 保存停车记录弹框数据
      queryPaymentList: [], // 保存停车记录弹框数据
      queryOrderList: undefined, // 保存停车记录弹框数据
      queryOrderLists: undefined, // 保存停车记录弹框数据
      payment: [],
      payScene: [], // 保存支付场景
      payStatus: [],
      zjine: undefined,
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    findQuery(code, list) {
      // 翻译
      const item = list.find((item) => item.code == code)
      return item ? item.name : ''
    },
    inTimechange() {
      this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 7 - 1000
    },
    outTimechange() {
      if (this.inTimeBegin === null || this.inTimeBegin === undefined) {
        this.inTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.inTimeEnd < this.inTimeBegin) {
        this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      } else if (this.inTimeEnd - this.inTimeBegin > 3600 * 1000 * 24 * 7) {
        this.inTimeEnd = this.inTimeBegin + 3600 * 1000 * 24 * 7 - 1000
        return this.$message.warning('不可选择比开始时间超过七天以外的日期')
      }
    },
    orderInfoexcel() {
      if (this.inTimeBegin - this.inTimeEnd > 3600 * 1000 * 24 * 7) {
        return this.$message.warning('只能导出一个礼拜以内的数据')
      }
      this.query.inTimeEnd = this.inTimeEnd
      this.query.inTimeBegin = this.inTimeBegin
      if (this.query.inTimeEnd || this.query.inTimeBegin) {
        this.query.inTimeEnd = (this.query.inTimeEnd / 1000).toFixed(0)
        this.query.inTimeBegin = (this.query.inTimeBegin / 1000).toFixed(0)
      }
      if (!this.query.inTimeBegin || !this.query.inTimeEnd) {
        return this.$message.warning('请选择导出的具体时间')
      }
      orderInfoexcel(this.query, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          window.open(res.data.data)
        }
      })
    },
    sortchange(column, prop, order) {
      if (column.prop == 'orderTime') {
        this.query.sortField = 'order_time'
      } else if (column.prop == 'orderTotalMoney') {
        this.query.sortField = 'order_total_money'
      }
      if (column.order == 'descending') {
        this.query.sortType = 'desc'
      } else if (column.order == 'ascending') {
        this.query.sortType = 'asc'
      }
      this.orderInfo()
    },
    // 计算两个时间差 dateBegin 开始时间
    timeFn(dateBegin, dateEnd) {
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      var dateDiff = dateEnd * 1000 - dateBegin * 1000 //时间差的毫秒数时间戳
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000)
      var leave4 = leave3 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var minseconds = Math.round(leave4 / 1000)
      // var timeFn = "耗时："+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒"+minseconds+"毫秒";
      var timeFn =
        dayDiff + '天 ' + hours + '小时 ' + minutes + ' 分钟' + seconds + ' 秒'
      return timeFn
    },
    orderInfo() {
      // 进出记录查询列表
      this.query.inTimeBegin = this.inTimeBegin
      this.query.inTimeEnd = this.inTimeEnd
      if (this.query.inTimeBegin && !this.query.inTimeEnd) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.inTimeBegin && this.query.inTimeEnd) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.inTimeBegin || this.query.inTimeEnd) {
        this.query.inTimeBegin = this.query.inTimeBegin / 1000
        this.query.inTimeEnd = this.query.inTimeEnd / 1000
      }
      orderInfo(this.query, { pk_id: this.query.pk_id }).then((res) => {
        this.total = res.data.data.total
        this.tableData = res.data.data.list
        findPayFailSumMoney(this.query, { pk_id: this.query.pk_id }).then(
          (res) => {
            if (res.data.code === '200') {
              this.zjine = res.data.data
              this.isShowData = true
            }
          }
        )
      })
    },
    orderInfocx() {
      // 查询
      this.query.current = 1
      this.query.size = 10
      this.orderInfo()
    },
    orderInfocz() {
      // 重置
      this.query.current = 1
      this.query.size = 10
      this.query.pk_id = this.PkInfoSelect[0].id
      this.query.plateNo = undefined
      this.query.sortField = 'order_time'
      this.query.sortType = 'desc'
      this.query.payStatus = undefined
      this.query.orderNo = undefined
      this.inTimeBegin = undefined
      this.inTimeEnd = undefined
      this.orderInfo()
    },
    sysDictData() {
      // 字典
      sysDictData({ typeId: 86 }).then((res) => {
        //支付方式
        this.payment = res.data.data
        sysDictData({ typeId: 97 }).then((res) => {
          //支付场景
          this.payScene = res.data.data
          sysDictData({ typeId: 94 }).then((res) => {
            //支付状态
            this.payStatus = res.data.data
          })
        })
      })
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pk_id = this.PkInfoSelect[0].id
          this.orderInfo()
          this.sysDictData()
        }
      })
    },
    queryParkings(id) {
      // 停车记录
      selectByInoutDatatk({ id: id }, { pk_id: this.query.pk_id }).then(
        (res) => {
          if (res.data.code === '200') {
            this.queryParkingList = res.data.data
            this.isShowParking = true
          }
        }
      )
    },
    queryPayment(id) {
      // 支付日志
      selectByPayLogtk({ id: id }, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          this.queryPaymentList = res.data.data
          this.isShowPayment = true
        }
      })
    },
    queryOrder(id) {
      // 订单详情
      selectOrderInfotk({ id: id }, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          this.queryOrderList = res.data.data
          console.log(this.queryOrderList.id)
          this.queryOrderLists = res.data.data.orderDetailDtos
          this.isShowOrder = true
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.query.size = val
      this.orderInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.query.current = val
      this.orderInfo()
    },
  },
}
</script>
<style lang="scss" scoped>
.txbj {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      width: 33.33333%;
      line-height: 40px;
      font-weight: 600;
      span {
        color: #409eff;
      }
      img {
        vertical-align: text-top;
      }
    }
  }
}
::v-deep .el-dialog__body {
  padding-top: 0px;
}
</style>
